import { Config } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

export const CRM_API = axios.create({
	baseURL: Config.CRM_API_URL,
	headers: {
		"Content-Type": "application/json"
	}
});
export const DL_API = axios.create({
	baseURL: Config.DL_API_URL,
	headers: {
		"Content-Type": "application/json"
	}
});
export const handleResponse = (response: any) => {
	console.log(response);
	// if (response?.status === 202) toast.error(response.data.error);
	// else if (response?.response?.status === 500) toast.error(response?.message);
	// else if (response?.response?.status === 401) {
	// 	Config.UNAUTHORIZED_EXCEPTION = true;
	// 	toast.error("You are not authorized for the action.");
	// } else
	if (response?.status === 200) return response?.data;
	else {
		console.log(response.error);
		toast.error("Something went wrong. Please contact server admin.");
	}
	return false;
};
