import { handleResponse, CRM_API, DL_API } from "./utils";

const app = {
	login: async (data: any) => {
		let response = null;
		try {
			response = await CRM_API.get("/api/v1/devices/get-device/", {
				params: {
					device_id: data.machineID
				}
			});
		} catch (e) {
			response = e;
		}
		return handleResponse(response);
	},
	dl_server_status: async () => {
		let response = null;
		try {
			response = await DL_API.get("/status/");
		} catch (e) {
			response = e;
		}
		return handleResponse(response);
	}
};

export default app;
