import { handleResponse, CRM_API, DL_API } from "./utils";

const analysis = {
	scan: async (data: any) => {
		// console.log(data);
		let response = null;
		try {
			response = await DL_API.post("/scan/", data);
		} catch (e) {
			response = e;
		}
		return handleResponse(response);
	},
	report: async (data: any) => {
		// console.log(data);
		let response = null;
		try {
			response = await DL_API.post("/report/", data);
		} catch (e) {
			response = e;
		}
		return handleResponse(response);
	},
	sendDiagnosis: async (data: any) => {
		// console.log(data);
		let response = null;
		try {
			response = await CRM_API.post("/api/v1/diagnosis/post-diagnosis", data);
		} catch (e) {
			response = e;
		}
		return handleResponse(response);
	},
	products: async (data: any) => {
		// console.log(data);
		let response = null;
		try {
			response = await CRM_API.get("/api/v1/recommendations/get-product-recommendation-v2/", {
				params: {
					scan_object_id: data.scan_object_id
				}
			});
		} catch (e) {
			response = e;
		}
		return handleResponse(response);
	}
};

export default analysis;
