import { FC, useCallback, useContext, useEffect, useState } from "react";
import { GeneralFootImg, LeftFootProfileImg } from "../../../assets";

import "./style.scss";
import { DIAGNOSIS_DATA } from "../../../constants/diagnosis";
import { generateDiagnosis } from "../../../utils/commonFunctions/diagnosisEngine";
import { useAnalysisFlow } from "../../../contexts";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import API from "../../../services/api";
import { TQuestion } from "../../../types/other.types";
import { PHQ } from "../../../constants/PHQ";

const LeftFootProfile: FC = () => {
	const { footcareAnalysis, setFootcareAnalysis, setCurrentIdx, handleNextClick, handlePrevClick } =
		useAnalysisFlow();
	const [activeDiagnosis, setActiveDiagnosis] = useState({
		text: "",
		mainPara: "",
		secondText: "",
		secondPara: "",
		diagnosis: "",
		painPoint: -1
	});

	const handlePainPointClick = useCallback(
		(painPoint: number, openPopup: boolean) => {
			//alert(painPoint);
			const _activeDiagnosis = footcareAnalysis.left_diagnosis.find(
				(eachLeftDiagnosis: any) => eachLeftDiagnosis.diagnosisPainPoint === painPoint
			);
			console.log("footcareAnalysis.left_diagnosis", _activeDiagnosis);
			setActiveDiagnosis(
				Object.assign(
					{},
					{
						text: DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].text,
						mainPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].mainPara,
						secondText:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondText,
						secondPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondPara,
						diagnosis: _activeDiagnosis.diagnosisName,
						painPoint: _activeDiagnosis.diagnosisPainPoint
					}
				)
			);
		},
		[footcareAnalysis.left_diagnosis]
	);

	const formatQuestionList = (questionList: any) => {
		const formattedQuestionList: TQuestion[] = [];
		for (const question of questionList) {
			const formatQuestion = {
				question: PHQ[Object.keys(question)[0]],
				is_correct: Boolean(Object.values(question)[0])
			};
			formattedQuestionList.push(formatQuestion);
		}
		// console.log("====>question", formattedQuestionList);
		return formattedQuestionList;
	};

	const sendData = async () => {
		try {
			const left_ques = footcareAnalysis.LEFT;
			console.log(footcareAnalysis.LEFT);
			const nonNullValues_LEFT = left_ques.filter((item: any) => Object.values(item)[0] !== null);

			console.log(nonNullValues_LEFT);

			const right_ques = footcareAnalysis.RIGHT;
			const nonNullValues_RIGHT = right_ques.filter((item: any) => Object.values(item)[0] !== null);
			const leftPainPoints = [];
			for (let i = 1; i <= 8; i++) {
				if (footcareAnalysis[`left_pain_point_${i}`]) {
					leftPainPoints.push(i);
				}
			}
			const rightPainPoints = [];
			for (let i = 1; i <= 8; i++) {
				if (footcareAnalysis[`right_pain_point_${i}`]) {
					rightPainPoints.push(i);
				}
			}
			// console.log(leftPainPoints, rightPainPoints);
			console.log({
				customer_scan_object_id: footcareAnalysis.scan_object_id,
				right_feet_pain_point_list: rightPainPoints,
				left_feet_pain_point_list: leftPainPoints,
				right_feet_question_list: formatQuestionList(nonNullValues_RIGHT),
				left_feet_question_list: formatQuestionList(nonNullValues_LEFT),
				left_feet_arc_type: footcareAnalysis.left_arch_type,
				right_feet_arc_type: footcareAnalysis.right_arch_type
			});
			const response = await API.sendDiagnosis({
				customer_scan_object_id: footcareAnalysis.scan_object_id,
				right_feet_pain_point_list: rightPainPoints,
				left_feet_pain_point_list: leftPainPoints,
				right_feet_question_list: formatQuestionList(nonNullValues_RIGHT),
				left_feet_question_list: formatQuestionList(nonNullValues_LEFT),
				left_feet_arc_type: footcareAnalysis.left_arch_type,
				right_feet_arc_type: footcareAnalysis.right_arch_type
			});

			console.log("data sent successfully!", response);
		} catch (error) {
			console.error("Error sending data:", error);
		} finally {
			console.log(" Data is sent");
		}
	};

	useEffect(() => {
		sendData();
	}, []);

	useEffect(() => {
		const leftDiagnosis = generateDiagnosis(footcareAnalysis, "LEFT");
		console.log("leftDiagnosis", leftDiagnosis);
		setFootcareAnalysis((prevFootcareAnalysis: any) => {
			return Object.assign({}, prevFootcareAnalysis, {
				left_diagnosis: [...leftDiagnosis],
				left_diagnosis_pain_points: leftDiagnosis.map(
					(eachleftDiagnosis: any) => eachleftDiagnosis.diagnosisPainPoint
				)
			});
		});
		if (leftDiagnosis.length === 0) {
			setCurrentIdx((prevCurrentIdx: number) => prevCurrentIdx + 1);
		} else {
			const _activeDiagnosis = leftDiagnosis[0];
			console.log(_activeDiagnosis);
			setActiveDiagnosis(
				Object.assign(
					{},
					{
						text: DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].text,
						mainPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].mainPara,
						secondText:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondText,
						secondPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondPara,
						diagnosis: _activeDiagnosis.diagnosisName,
						painPoint: _activeDiagnosis.diagnosisPainPoint
					}
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setFootcareAnalysis]);
	//console.log("diagnosis", activeDiagnosis);
	return (
		<div className="left-foot-profile h-full w-full relative flex flex-col items-center">
			<div className="sub-header text-center bg-secondary text-white">Your Left Foot Profile</div>
			<div className="flex flex-row h-fit mt-16 max-h-[800px] w-full">
				<div className="h-full w-3/5 relative image-container">
					<img src={LeftFootProfileImg} alt="" className="profile-img" />
					<div className="arch-type">{footcareAnalysis.left_arch_type}</div>
					{footcareAnalysis.left_diagnosis_pain_points.includes(3) ? (
						<div
							className={`profile-3 profile-dot ${
								activeDiagnosis.painPoint === 3 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(3, false)}
						/>
					) : null}
					{footcareAnalysis.left_diagnosis_pain_points.includes(2) ? (
						<div
							className={`profile-2 profile-dot ${
								activeDiagnosis.painPoint === 2 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(2, false)}
						/>
					) : null}
					{footcareAnalysis.left_diagnosis_pain_points.includes(1) ? (
						<div
							className={`profile-1 profile-dot ${
								activeDiagnosis.painPoint === 1 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(1, false)}
						/>
					) : null}
					{footcareAnalysis.left_diagnosis_pain_points.includes(4) ? (
						<div
							className={`profile-4 profile-dot ${
								activeDiagnosis.painPoint === 4 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(4, false)}
						/>
					) : null}
					{footcareAnalysis.left_diagnosis_pain_points.includes(7) ? (
						<div
							className={`profile-7 profile-dot ${
								activeDiagnosis.painPoint === 7 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(7, false)}
						/>
					) : null}
					{footcareAnalysis.left_diagnosis_pain_points.includes(8) ? (
						<div
							className={`profile-8 profile-dot ${
								activeDiagnosis.painPoint === 8 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(8, false)}
						/>
					) : null}
					{footcareAnalysis.left_diagnosis_pain_points.includes(6) ? (
						<div
							className={`profile-6 profile-dot ${
								activeDiagnosis.painPoint === 6 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(6, false)}
						/>
					) : null}
				</div>
				<div className="h-full w-2/5 pr-16">
					<div className="text-[2vw] font-bold border-l-8 border-primary pl-4">
						{activeDiagnosis.diagnosis}
					</div>
					<div className="text-[1.2vw] mt-10">{activeDiagnosis.text}</div>
				</div>
			</div>
			<div className="bottom-pointer-left" onClick={handlePrevClick}>
				<MdKeyboardDoubleArrowLeft className="hover:scale-90 text-3xl" />
				<span className="text-[1.5vw] font-bold ml-3">Back</span>
			</div>
			<div className="bottom-pointer-right" onClick={handleNextClick}>
				<span className="text-[1.5vw] font-bold">Next</span>
				<MdKeyboardDoubleArrowRight className="hover:scale-90 text-3xl ml-3" />
			</div>
		</div>
	);
};

export default LeftFootProfile;
