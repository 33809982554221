import { FC } from "react";
import WebcamCard from "../../../components/WebcamCard";
import { useAnalysisFlow, useLoading, useStore } from "../../../contexts";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { ImageDataObject, payloadOfFootStorApi } from "../../../types/other.types";
import API from "../../../services/api";

const WebcamPage: FC = () => {
	const { footcareAnalysis, setFootcareAnalysis, handlePrevClick, handleNextClick } = useAnalysisFlow();
	console.log(footcareAnalysis);
	const { setLoading } = useLoading();
	const { storeData } = useStore();
	const store = storeData?.store;
	const videoWidth = storeData?.cameraSettings.width;
	const videoHeight = storeData?.cameraSettings.height;
	// console.log(storeData?.cameraSettings.leftTop);

	const sendImagesToApi = async (imageDataObject: ImageDataObject) => {
		try {
			const payloadOfFootStorApi: payloadOfFootStorApi = {
				Left_Side: imageDataObject.leftSide.replace("data:image/png;base64,", ""),
				Right_Side: imageDataObject.rightSide.replace("data:image/png;base64,", ""),
				Left_Top: imageDataObject.leftTop.replace("data:image/png;base64,", ""),
				Right_Top: imageDataObject.rightTop.replace("data:image/png;base64,", ""),
				name: footcareAnalysis.name,
				Sex: footcareAnalysis.gender,
				min_age: footcareAnalysis.minimum_age,
				max_age: footcareAnalysis.maximum_age,
				left_adjustment: storeData?.cameraSettings.leftAdjustment,
				right_adjustment: storeData?.cameraSettings.rightAdjustment,
				leftPPU: storeData?.cameraSettings.leftPPU,
				rightPPU: storeData?.cameraSettings.rightPPU,
				group_number: store.group_number,
				group_name: store.group_name,
				store_number: store.store_number,
				store_name: store.store_name,
				dev_id: store.machine_id,
				weight: footcareAnalysis.weight,
				height: footcareAnalysis.height,
				right_top_horizontal_value: storeData?.cameraSettings.rightTopHorizentalValue,
				right_top_vertical_value: storeData?.cameraSettings.rightTopVerticalValue,
				right_side_horizontal_value: storeData?.cameraSettings.rightSideHorizentalValue,
				right_side_vertical_value: storeData?.cameraSettings.rightSideVerticalValue,
				left_top_horizontal_value: storeData?.cameraSettings.leftTopHorizentalValue,
				left_top_vertical_value: storeData?.cameraSettings.leftTopVerticalValue,
				left_side_horizontal_value: storeData?.cameraSettings.leftSideHorizentalValue,
				left_side_vertical_value: storeData?.cameraSettings.leftSideVerticalValue,
				leftTopDots: {
					dot1: storeData?.cameraSettings.leftTopDOT1,
					dot2: storeData?.cameraSettings.leftTopDOT2
				},
				rightTopDots: {
					dot1: storeData?.cameraSettings.rightTopDOT1,
					dot2: storeData?.cameraSettings.rightTopDOT2
				},
				leftSideDots: {
					dot1: storeData?.cameraSettings.leftSideDOT1,
					dot2: storeData?.cameraSettings.leftSideDOT2
				},
				rightSideDots: {
					dot1: storeData?.cameraSettings.rightSideDOT1,
					dot2: storeData?.cameraSettings.rightSideDOT2
				}
			};

			console.log(payloadOfFootStorApi);
			// Send the POST request using Axios with the cleaned image data
			const response = await API.scan(payloadOfFootStorApi);
			console.log("Images sent successfully!", response);
			// const data = response.data;
			if (!response.Left_Length && !response.Right_Length) {
				return console.log("Error sending images to server!");
			}
			setFootcareAnalysis((prevFootcareAnalysis: any) => ({
				...prevFootcareAnalysis,
				left_length: response["Left_Length"],
				left_width: response["Left_Width"],
				left_instep_height: response["Left_Instep_Height"],
				left_arch_height: response["Left_Arch_Height"],
				right_length: response["Right_Length"],
				right_width: response["Right_Width"],
				right_instep_height: response["Right_Instep_Height"],
				right_arch_height: response["Right_Arch_Height"],
				left_shoe_size: response["Left_shoe_size_UK"],
				right_shoe_size: response["Right_shoe_size_UK"],
				left_arch_type: response["Arch_Type_Left"],
				right_arch_type: response["Arch_Type_Right"],
				left_shoe_size_eu: response["Left_shoe_size_EU"],
				right_shoe_size_eu: response["Right_shoe_size_EU"],
				right_width_type: response["Width_Type_Right"],
				left_width_type: response["Width_Type_Left"],
				scan_id: response["scan_id"],
				scan_object_id: response["scan_object_id"],
				ppu_left: response["ppu_left"],
				ppu_right: response["ppu_right"],
				adj_right: response["adj_right"],
				adj_left: response["adj_left"],
				left_heel_width: response["Left_Heel_Width"],
				right_heel_width: response["Right_Heel_Width"],
				marker_distance_left: response["Marker_Distance_Left"],
				marker_distance_right: response["Marker_Distance_Right"]
			}));
			handleNextClick();
			// You can handle the API response here
		} catch (error) {
			console.log("Error sending images:", error);
			// Handle errors here
		}
	};

	// Function to capture images from webcams and update the capturedImages state
	const captureImages = async () => {
		setLoading(true);
		// Create an object to store captured images
		const capturedImagesObject: ImageDataObject = {
			leftSide: "",
			rightSide: "",
			leftTop: "",
			rightTop: ""
		};
		// Function to capture an image from a webcam and update the state
		async function captureImage(deviceId: string, propertyName: keyof ImageDataObject) {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			// console.log(deviceId);

			if (ctx) {
				const video = document.createElement("video");
				video.autoplay = true;
				video.playsInline = true;

				try {
					const stream = await navigator.mediaDevices.getUserMedia({
						video: {
							deviceId,
							width: storeData?.cameraSettings.width,
							height: storeData?.cameraSettings.height
						}
					});
					video.srcObject = stream;

					// Wait for the video metadata to load
					await new Promise<void>((resolve: any) => {
						video.onloadedmetadata = () => {
							canvas.width = video.videoWidth;
							canvas.height = video.videoHeight;
							const cropLeft = 0;
							const cropTop = 0;
							const cropWidth = canvas.width;
							const cropHeight = canvas.height;

							ctx.drawImage(video, cropLeft, cropTop, cropWidth, cropHeight);
							const base64Image = canvas.toDataURL("image/png");
							capturedImagesObject[propertyName] = base64Image;
							// console.log(base64Image);
							stream.getTracks().forEach((track: any) => track.stop());
							resolve();
						};
					});
				} catch (error) {
					console.error(`Error accessing webcam ${deviceId}:`, error);
				}
			}
		}
		// Create an array of promises to capture images from webcams
		const promises = [
			captureImage(storeData?.cameraSettings.leftTop.deviceId, "leftTop"),
			captureImage(storeData?.cameraSettings.rightTop.deviceId, "rightTop"),
			captureImage(storeData?.cameraSettings.leftSide.deviceId, "leftSide"),
			captureImage(storeData?.cameraSettings.rightSide.deviceId, "rightSide")
		];
		// Wait for all promises to resolve
		await Promise.all(promises);
		// Send the capturedImagesObject to the API
		await sendImagesToApi(capturedImagesObject);
		setLoading(false);
	};

	return (
		<div className="relative h-full w-full">
			<div className="sub-header text-center bg-secondary text-white">
				Please Stand Steady For Capture Your Foot
			</div>
			<div className="w-full flex flex-col items-center justify-center h-[90%] py-5% overflow-auto">
				<div className="flex flex-row items-center justify-center">
					<div className="">
						<WebcamCard
							selectedDeviceId={storeData?.cameraSettings.leftSide.deviceId}
							verticalValue={storeData?.cameraSettings.leftSideVerticalValue}
							horizontalValue={storeData?.cameraSettings.leftSideHorizentalValue}
							videoHeight={videoHeight}
							videoWidth={videoWidth}
							title="Left Side"
							dotP1={storeData?.cameraSettings.leftSideDOT1}
							dotP2={storeData?.cameraSettings.leftSideDOT2}
						/>
					</div>
					<div className="ml-5">
						<WebcamCard
							selectedDeviceId={storeData?.cameraSettings.leftTop.deviceId}
							verticalValue={storeData?.cameraSettings.leftTopVerticalValue}
							horizontalValue={storeData?.cameraSettings.leftTopHorizentalValue}
							videoHeight={videoHeight}
							videoWidth={videoWidth}
							title="Left Top"
							dotP1={storeData?.cameraSettings.leftTopDOT1}
							dotP2={storeData?.cameraSettings.leftTopDOT2}
						/>
					</div>
				</div>
				<div className="flex flex-row items-center justify-center mt-5">
					<div className="">
						<WebcamCard
							selectedDeviceId={storeData?.cameraSettings.rightTop.deviceId}
							verticalValue={storeData?.cameraSettings.rightTopVerticalValue}
							horizontalValue={storeData?.cameraSettings.rightTopHorizentalValue}
							videoHeight={videoHeight}
							videoWidth={videoWidth}
							title="Right Top"
							dotP1={storeData?.cameraSettings.rightTopDOT1}
							dotP2={storeData?.cameraSettings.rightTopDOT2}
						/>
					</div>
					<div className="ml-5">
						<WebcamCard
							selectedDeviceId={storeData?.cameraSettings.rightSide.deviceId}
							verticalValue={storeData?.cameraSettings.rightSideVerticalValue}
							horizontalValue={storeData?.cameraSettings.rightSideHorizentalValue}
							videoHeight={videoHeight}
							videoWidth={videoWidth}
							title="Right Side"
							dotP1={storeData?.cameraSettings.rightSideDOT1}
							dotP2={storeData?.cameraSettings.rightSideDOT2}
						/>
					</div>
				</div>
			</div>

			<div className="bottom-pointer-left" onClick={handlePrevClick}>
				<MdKeyboardDoubleArrowLeft className="hover:scale-90 text-3xl" />
				<span className="text-[1.5vw] font-bold ml-3">Back</span>
			</div>
			<div
				className="bottom-pointer-right"
				onClick={() => {
					captureImages();
				}}
			>
				<span className="text-[1.5vw] font-bold">Next</span>
				<MdKeyboardDoubleArrowRight className="hover:scale-90 text-3xl ml-3" />
			</div>
		</div>
	);
};

export default WebcamPage;
