import { FC } from "react";
import "./style.scss";
import Button from "@mui/material-next/Button";
import * as React from "react";
import { TextField } from "@mui/material";
import { useAnalysisFlow, useLoading, useStore } from "../../../contexts";
import API from "../../../services/api";

const IndentifyingInformation: FC = () => {
	const { footcareAnalysis, setFootcareAnalysis, handleNextClick, handlePrevClick } = useAnalysisFlow();
	const { setLoading } = useLoading();
	const { storeData } = useStore();
	const store = storeData?.store;

	const sendDataToApi = async () => {
		setLoading(true);
		const payload = {
			scan_id: footcareAnalysis.scan_id,
			name: footcareAnalysis.name,
			phone: footcareAnalysis.phone,
			contact_number: footcareAnalysis.phone_number,
			sex: footcareAnalysis.gender,
			min_age: footcareAnalysis.minimum_age,
			max_age: footcareAnalysis.maximum_age,
			group_number: store.group_number,
			store_number: store.store_number
		};
		try {
			const response = await API.report(payload);

			console.log("data sent successfully!", response);
			const data = response;

			if (data["link"]) {
				setFootcareAnalysis((prevFootcareAnalysis: any) => ({ ...prevFootcareAnalysis, link: data["link"] }));
			}
			console.log("link", data["link"]);
			handleNextClick();
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFootcareAnalysis((prevFootcareAnalysis: any) => ({ ...prevFootcareAnalysis, [name]: value }));
	};

	return (
		<>
			<div className="identifying-information w-full">
				<div className="sub-header text-center bg-secondary text-white">Your Analysis Is Ready To Generate</div>
				<div className="w-1/3 min-w-96 h-full flex flex-col items-center mt-20 shadow-xl px-20 py-20 bg-green-50 rounded">
					<div className="flex flex-row items-center w-full justify-center">
						<TextField
							required={true}
							className="w-full"
							variant="outlined"
							size="medium"
							name="name"
							placeholder="Enter Your Name"
							onChange={handleChange}
							autoComplete="off"
						/>
					</div>
					<div className="flex flex-row items-center w-full justify-center mt-10">
						<TextField
							required={true}
							className="w-full"
							variant="outlined"
							size="medium"
							name="phone"
							placeholder="Enter Your Phone Number"
							onChange={handleChange}
							autoComplete="off"
						/>
					</div>
					<div className="mt-12">
						<Button
							sx={{
								textAlign: "center",
								fontSize: "1.2vw",
								fontFamily: "inherit",
								fontWeight: "bold",
								paddingLeft: "2rem",
								paddingRight: "2rem",
								background: "#60cf63",
								color: "#1f2149"
							}}
							disabled={false}
							size="large"
							onClick={sendDataToApi}
						>
							Generate Report
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default IndentifyingInformation;
