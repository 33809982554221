// Current environment (string type with specific allowed values)
export const CRM_APP_ENV = "PROD" as "PROD" | "DEV" | "LOCAL"; // Restrict allowed values using `as`
export const DL_APP_ENV = "PROD" as "PROD" | "DEV" | "LOCAL"; // Restrict allowed values using `as`

export const version = "v1"; // Application version

// Backend URLs for different environments
export const stagingCRMURL = "http://172.24.6.52:4008";
export const prodCRMURL = "https://d2w39smwctjse1.cloudfront.net";

// Dynamically determine the URL based on APP_ENV
export const crm_url =
	CRM_APP_ENV === "PROD"
		? prodCRMURL
		: CRM_APP_ENV === "DEV" || CRM_APP_ENV === "LOCAL"
		? stagingCRMURL
		: (() => {
				throw new Error("Invalid APP_ENV value. Must be 'PROD', 'DEV', or 'LOCAL'.");
		  })();

// Backend URLs for different environments
export const stagingDLURL = "http://172.24.6.52:4008";
export const prodDLURL = "https://d2w39smwctjse1.cloudfront.net";

// Dynamically determine the URL based on APP_ENV
export const dl_url =
	DL_APP_ENV === "PROD"
		? prodDLURL
		: DL_APP_ENV === "DEV" || DL_APP_ENV === "LOCAL"
		? stagingDLURL
		: (() => {
				throw new Error("Invalid APP_ENV value. Must be 'PROD', 'DEV', or 'LOCAL'.");
		  })();

// Local storage key (currently empty but can be updated)
export const LOCAL_STORE_KEY = "";

// Centralized configuration object
export const Config = {
	CRM_API_URL: crm_url, // Deep Learning API URL
	DL_API_URL: dl_url, // Deep Learning API URL
	UNAUTHORIZED_EXCEPTION: false, // Flag for unauthorized exceptions
	TOKEN: null // Placeholder for authentication token
};
