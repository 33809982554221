import { handleResponse, CRM_API } from "./utils";

const settings = {
	get_ppu: async (data: any) => {
		// console.log(data);
		let response = null;
		try {
			response = await CRM_API.post("/ppu/", data);
		} catch (e) {
			response = e;
		}
		return handleResponse(response);
	}
};

export default settings;
