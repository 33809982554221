import React, { FC, useState, useContext } from "react";
import "./appBar.css";
import { GroupLogo, Logo } from "../../assets";

import { IoMdSettings } from "react-icons/io";
import {
	AppBar,
	Box,
	Button,
	CssBaseline,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Toolbar,
	Typography
} from "@mui/material";
import { AiFillHome, AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import Settings from "../settingModal";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useStore } from "../../contexts";

const drawerWidth = 240;

const AppBarComp: FC = (): JSX.Element => {
	const { storeData, setStoreDetails } = useStore();
	// console.log(storeData);
	const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const navigate = useNavigate();
	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	const handleLogoutOnClick = () => {
		setStoreDetails(null);
		navigate("/login");
	};

	const handleOpenSettingsDialog = () => {
		setOpenSettingsDialog(true);
	};

	const handleCloseSettingsDialog = () => {
		setOpenSettingsDialog(false);
	};

	const handleClick = () => {
		navigate("/");
	};

	const drawer = (
		<Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
			<img
				src={storeData?.store?.["group-details"]?.logo}
				alt="Group Logo"
				style={{ maxWidth: "100%", height: 80 }}
			/>

			<Divider />
			<List>
				<ListItem disablePadding>
					<Button onClick={handleClick}>
						<span>
							<AiFillHome className="icon" />
						</span>
						<span className="mgr-10 text-secondary font-bold">Home</span>
					</Button>
				</ListItem>
				<ListItem disablePadding>
					<Button onClick={handleOpenSettingsDialog}>
						<span>
							<IoMdSettings className="icon" />
						</span>
						<span className="mgr-10 text-secondary font-bold">Settings</span>
					</Button>
				</ListItem>
				{storeData?.store && (
					<ListItem disablePadding>
						<Button onClick={handleLogoutOnClick}>
							<span>
								<AiOutlineLogout className="icon" />
							</span>
							<span className="mgr-10 text-secondary font-bold">Logout</span>
						</Button>
					</ListItem>
				)}
			</List>
		</Box>
	);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar component="nav" color="transparent" className="h-[10%]">
				<Toolbar className="bg-[#ffffff95] h-full backdrop-blur-sm">
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: "none" } }}
					>
						<MenuIcon className="text-secondary" />
					</IconButton>
					<div className="group-logo" style={{ overflow: "hidden" }}>
						<img
							src={storeData?.store?.["group-details"]?.logo}
							alt="Group Logo"
							style={{ maxWidth: "100%", height: 80 }}
						/>
					</div>
					<Box sx={{ display: { xs: "none", sm: "block" } }}>
						{storeData?.store && (
							<div className="nav-links">
								<Button onClick={handleClick}>
									<span className="mgr-10 text-secondary font-bold">Home</span>
									<span>
										<AiFillHome className="icon" />
									</span>
								</Button>
								<Button onClick={handleOpenSettingsDialog}>
									<span className="mgr-10 text-secondary font-bold">Settings</span>
									<span>
										<IoMdSettings className="icon" />
									</span>
								</Button>
								<Button onClick={handleLogoutOnClick}>
									<span className="mgr-10 text-secondary font-bold">Logout</span>
									<span>
										<AiOutlineLogout className="icon" />
									</span>
								</Button>
								<img src={Logo} />
							</div>
						)}
						<Settings
							openSettingsDialog={openSettingsDialog}
							handleCloseSettingsDialog={handleCloseSettingsDialog}
						/>
					</Box>
				</Toolbar>
			</AppBar>
			<nav>
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
					}}
				>
					{drawer}
				</Drawer>
			</nav>
		</Box>
	);
};

export default AppBarComp;
