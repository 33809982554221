import { FC, useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import "./style.scss";
import MultiActionAreaCard from "./recommendedProducts";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useAnalysisFlow } from "../../../contexts";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import API from "../../../services/api";
import { PHQ } from "../../../constants/PHQ";
import { TQuestion } from "../../../types/other.types";

interface product {
	product_image: string;
	product_name: string;
	product_category: string;
	product_area: string;
	description: string;
}

const Recommendations: FC = () => {
	const { footcareAnalysis, setFootcareAnalysis, setCurrentIdx, handleNextClick, handlePrevClick } =
		useAnalysisFlow();
	const [leftFeetProducts, setleftFeetProducts] = useState<Array<product>>();
	const [rightFeetProducts, setrightFeetProducts] = useState<Array<product>>();

	const formatQuestionList = (questionList: any) => {
		const formattedQuestionList: TQuestion[] = [];
		for (const question of questionList) {
			const formatQuestion = {
				question: PHQ[Object.keys(question)[0]],
				is_correct: Boolean(Object.values(question)[0])
			};
			formattedQuestionList.push(formatQuestion);
		}
		// console.log("====>question", formattedQuestionList);
		return formattedQuestionList;
	};

	const sendData = async () => {
		try {
			console.log(" Data is trying to send");
			const right_diagnosisName = footcareAnalysis.right_diagnosis.map(
				(diagnosis: { diagnosisName: string }) => diagnosis.diagnosisName
			);
			const left_diagnosisName = footcareAnalysis.left_diagnosis.map(
				(diagnosis: { diagnosisName: string }) => diagnosis.diagnosisName
			);
			const right_diagnosisPainPoint = footcareAnalysis.right_diagnosis.map(
				(diagnosis: { diagnosisPainPoint: number }) => diagnosis.diagnosisPainPoint
			);
			const left_diagnosisPainPoint = footcareAnalysis.left_diagnosis.map(
				(diagnosis: { diagnosisPainPoint: number }) => diagnosis.diagnosisPainPoint
			);

			const left_ques = footcareAnalysis.LEFT;
			console.log(footcareAnalysis.LEFT);
			const nonNullValues_LEFT = left_ques.filter((item: any) => Object.values(item)[0] !== null);

			console.log(nonNullValues_LEFT);

			const right_ques = footcareAnalysis.RIGHT;
			const nonNullValues_RIGHT = right_ques.filter((item: any) => Object.values(item)[0] !== null);

			console.log({
				customer_scan_object_id: footcareAnalysis.scan_object_id,
				right_feet_diagnosis_name: right_diagnosisName[0],
				right_feet_pain_point: right_diagnosisPainPoint,
				left_feet_diagnosis_name: left_diagnosisName[0],
				left_feet_pain_point: left_diagnosisPainPoint,
				right_feet_question_list: formatQuestionList(nonNullValues_RIGHT),
				left_feet_question_list: formatQuestionList(nonNullValues_LEFT),
				left_feet_arc_type: footcareAnalysis.left_arch_type,
				right_feet_arc_type: footcareAnalysis.right_arch_type
			});

			const response = await API.sendDiagnosis({
				customer_scan_object_id: footcareAnalysis.scan_object_id,
				right_feet_diagnosis_name: right_diagnosisName[0],
				right_feet_pain_point: right_diagnosisPainPoint[0],
				left_feet_diagnosis_name: left_diagnosisName[0],
				left_feet_pain_point: left_diagnosisPainPoint[0],
				right_feet_question_list: formatQuestionList(nonNullValues_RIGHT),
				left_feet_question_list: formatQuestionList(nonNullValues_LEFT),
				left_feet_arc_type: footcareAnalysis.left_arch_type,
				right_feet_arc_type: footcareAnalysis.right_arch_type
			});

			console.log("data sent successfully!", response);
			if (response) {
				getRecommendedProducts();
			}
		} catch (error) {
			console.error("Error sending data:", error);
		} finally {
			console.log(" Data is sent");
		}
	};

	const getRecommendedProducts = async () => {
		try {
			const response = await API.products({ scan_object_id: footcareAnalysis.scan_object_id });

			console.log("data sent successfully!", response);
			const data = response;

			console.log(data);

			const leftFeetProducts = data.result.leftFeetProducts.map((element: any) => {
				return {
					product_name: element.product_name,
					product_category: element.product_category,
					product_area: element.product_area,
					description: element.description,
					product_image: element.product_image
				};
			});

			const rightFeetProducts = data.result.rightFeetProducts.map((element: any) => {
				return {
					product_name: element.product_name,
					product_category: element.product_category,
					product_area: element.product_area,
					description: element.description,
					product_image: element.product_image
				};
			});

			setleftFeetProducts(leftFeetProducts);
			setrightFeetProducts(rightFeetProducts);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		// sendData();
		getRecommendedProducts();
	}, []);

	return (
		<div className="h-full w-full relative flex flex-col items-center">
			<div className="sub-header text-center bg-secondary text-white">Recommended Products</div>
			<div className="w-full h-full overflow-auto pb-12">
				{leftFeetProducts && leftFeetProducts.length > 0 && (
					<div className="">
						<div className="text-2xl bg-slate-200 shadow-sm mb-1 p-1 pr-5 w-fit text-secondary">
							For Your Left Feet
						</div>
						<div className="flex flex-row overflow-x-auto py-2">
							{leftFeetProducts.map((product, index) => {
								return <MultiActionAreaCard key={index} productDetails={product} />;
							})}
						</div>
					</div>
				)}
				{rightFeetProducts && rightFeetProducts.length > 0 && (
					<div className="mt-5">
						<div className="text-2xl bg-slate-200 shadow-sm mb-1 p-1 pr-5 w-fit text-secondary">
							For Your Right Feet
						</div>
						<div className="flex flex-row overflow-x-auto py-2">
							{rightFeetProducts.map((product, index) => {
								return <MultiActionAreaCard key={index} productDetails={product} />;
							})}
						</div>
					</div>
				)}
			</div>
			<div
				className="bottom-pointer-left"
				onClick={() => {
					handlePrevClick();
				}}
			>
				<MdKeyboardDoubleArrowLeft className="hover:scale-90 text-3xl" />
				<span className="text-[1.5vw] font-bold ml-3">Back</span>
			</div>
		</div>
	);
};

export default Recommendations;
